<template>
  <el-dialog
    title="明细"
    :visible.sync="showDialog"
    :close-on-click-modal="false"
    :modal="false"
    fullscreen
  >
    <div v-loading="loading" class="orderTest-container">
      <div class="title">
        员工:{{ form.user_name }} -- {{ timeType }}日期:{{ form.time_at }}
      </div>
    </div>
    <div class="table-wrap">
      <div class="table">
        <!-- 销售统计 -->
        <el-table
          stripe
          :data="list.sale"
          show-summary
          :summary-method="summaryMethod1"
        >
          <el-table-column
            v-for="(item, tableIndex) in columns1"
            :key="tableIndex"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            align="center"
          >
            <template #default="{ row }">
              <div
                v-if="
                  item.label == '协议单号' ||
                  item.label == '订单' ||
                  item.label == '销售单据'
                "
                class="underLine"
                @click="orderDetail(row)"
              >
                {{ row[item.prop] }}
              </div>
              <div v-else>{{ row[item.prop] }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="table">
        <el-table
          stripe
          :data="list.deposit"
          show-summary
          :summary-method="summaryMethod2"
        >
          <el-table-column
            v-for="(item, tableIndex) in columns2"
            :key="tableIndex"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            align="center"
          >
            <template #default="{ row }">
              <div
                v-if="
                  item.label == '协议单号' ||
                  item.label == '订单' ||
                  item.label == '销售单据'
                "
                class="underLine"
                @click="orderDetail(row)"
              >
                {{ row[item.prop] }}
              </div>
              <div v-else>{{ row[item.prop] }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="table">
        <el-table
          stripe
          :data="list.clear"
          show-summary
          :summary-method="summaryMethod3"
        >
          <el-table-column
            v-for="(item, tableIndex) in columns3"
            :key="tableIndex"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            align="center"
          >
            <template #default="{ row }">
              <div
                v-if="
                  item.label == '协议单号' ||
                  item.label == '订单' ||
                  item.label == '销售单据'
                "
                class="underLine"
                @click="orderDetail(row)"
              >
                {{ row[item.prop] }}
              </div>
              <div v-else>{{ row[item.prop] }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="table">
        <el-table
          stripe
          :data="list.prize"
          show-summary
          :summary-method="summaryMethod4"
        >
          <el-table-column
            v-for="(item, tableIndex) in columns4"
            :key="tableIndex"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            align="center"
          >
            <template #default="{ row }">
              <div
                v-if="
                  item.label == '协议单号' ||
                  item.label == '订单' ||
                  item.label == '销售单据'
                "
                class="underLine"
                @click="orderDetail(row)"
              >
                {{ row[item.prop] }}
              </div>
              <div v-else>{{ row[item.prop] }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="table">
        <el-table
          stripe
          :data="list.back"
          show-summary
          :summary-method="summaryMethod5"
        >
          <el-table-column
            v-for="(item, tableIndex) in columns5"
            :key="tableIndex"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            align="center"
          >
            <template #default="{ row }">
              <div
                v-if="
                  item.label == '协议单号' ||
                  item.label == '订单' ||
                  item.label == '销售单据'
                "
                class="underLine"
                @click="orderDetail(row)"
              >
                {{ row[item.prop] }}
              </div>
              <div v-else>{{ row[item.prop] }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </el-dialog>
</template>
<script>
  import { postAction } from '@/api/Employee'
  export default {
    name: 'Detail',
    components: {},
    data() {
      return {
        showDialog: false,
        loading: false,
        list: [],
        form: {},
        // 销售
        columns1: [
          { label: '销售统计-客户名称', prop: 'cust_name', width: '' },
          { label: '销售单据', prop: 'bill_code', width: '' },
          { label: '方式', prop: 'bill_type_text', width: '' },
          { label: '状态', prop: 'bill_status', width: '' },
          { label: '已结清', prop: 'pay_status_text', width: '' },
          { label: '订单金额', prop: 'total_amount', width: '' },
          { label: '销售收款', prop: 'sale_money', width: '' },
          { label: '派送收款', prop: 'driver_money', width: '' },
        ],
        columns2: [
          { label: '预存款/预存货统计-客户名称', prop: 'cust_name', width: '' },
          { label: '协议单号', prop: 'bill_code', width: '' },
          { label: '类型', prop: 'type_name', width: '' },
          { label: '签单金额', prop: 'total_amount', width: '' },
          { label: '实收款', prop: 'collection_money', width: '' },
        ],
        columns3: [
          { label: '清欠统计-客户名称', prop: 'cust_name', width: '' },
          { label: '订单', prop: 'bill_code', width: '' },
          { label: '收款', prop: 'money', width: '' },
        ],
        columns4: [
          { label: '兑奖/兑付现金-客户名称', prop: '', width: '' },
          { label: '订单', prop: 'bill_code', width: '' },
          { label: '类型', prop: 'bill_type_text', width: '' },
          { label: '现金', prop: 'money', width: '' },
        ],
        columns5: [
          { label: '预存退款统计-客户名称', prop: 'cust_name', width: '' },
          { label: '协议单号', prop: 'bill_code', width: '' },
          { label: '类型', prop: 'type_name', width: '' },
          { label: '退款', prop: 'money', width: '' },
        ],
        data: {},
      }
    },
    computed: {
      timeType() {
        if (this.form.time_type == '2') {
          return '下单'
        } else if (this.form.time_type == '1') {
          return '到货'
        } else {
          return '结清'
        }
      },
    },
    watch: {
      showDialog(v) {
        if (v) {
          this.fetchData()
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data } = await postAction(
          '/reportAdmin/operate/day-form-detail',
          this.form
        )
        this.list = data
        console.log('list', this.list)
        this.loading = false
      },
      summaryFunction(param, idx) {
        const { columns, data } = param
        // 需要合计的下标
        let i = idx
        let sums = []
        // let val = data.map((item) => {
        //   console.log(item)
        // })
        columns.forEach((col, idx) => {
          i.forEach((n) => {
            if (idx == n) {
              console.log(col.property)
              const val = data.map((item) => Number(item[col.property]))
              console.log(val)
              if (!val.every((value) => isNaN(value))) {
                const a = val.reduce((total, num) => {
                  if (!isNaN(total)) {
                    return total + num
                  }
                })
                sums[n] = a.toFixed(2) + ' 元'
              }
            }
          })
          if (idx == 0) {
            sums[idx] = '合计'
          }
        })
        console.log('sss', sums)
        return sums
      },
      summaryMethod1(param) {
        return this.summaryFunction(param, [5, 6, 7])
      },
      summaryMethod2(param) {
        return this.summaryFunction(param, [4])
      },
      summaryMethod3(param) {
        return this.summaryFunction(param, [2])
      },
      summaryMethod4(param) {
        return this.summaryFunction(param, [3])
      },
      summaryMethod5(param) {
        return this.summaryFunction(param, [3])
      },
      orderDetail(row) {},
    },
  }
</script>
<style lang="scss" scoped>
  .title {
    margin-bottom: 10px;
    text-align: center;
  }
  .table {
    margin-right: 15px;
  }
</style>
