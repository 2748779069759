var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            inline: "",
            "label-position": "right",
            "label-width": "50px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  on: { click: _vm.handleExport },
                },
                [_vm._v(" 导出 ")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  on: { click: _vm.handleBack },
                },
                [_vm._v(" 返回 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { stripe: "", data: _vm.list },
        },
        [
          _vm._l(_vm.columns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                prop: item.prop,
                label: item.label,
                width: item.width,
                align: "center",
              },
            })
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "90", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDetail(row)
                          },
                        },
                      },
                      [_vm._v("明细")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("div", { staticClass: "total" }, [
        _c("div", [_vm._v("销售收款合计:" + _vm._s(_vm.totalAll.sale_money))]),
        _c("div", [
          _vm._v("派送收款合计:" + _vm._s(_vm.totalAll.driver_money)),
        ]),
        _c("div", [
          _vm._v("预存款收款合计:" + _vm._s(_vm.totalAll.deposit_money)),
        ]),
        _c("div", [
          _vm._v("预存货收款合计:" + _vm._s(_vm.totalAll.goods_money)),
        ]),
        _c("div", [_vm._v("清欠收款合计:" + _vm._s(_vm.totalAll.clear_money))]),
        _c("div", [
          _vm._v("兑奖现金合计:" + _vm._s(_vm.totalAll.prize_amount)),
        ]),
        _c("div", [_vm._v("预存退款合计:" + _vm._s(_vm.totalAll.back_money))]),
        _c("div", [_vm._v("收支合计:" + _vm._s(_vm.totalAll.total_amount))]),
      ]),
      _c("detail", { ref: "detail" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }