<template>
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="50px"
    >
      <el-form-item label="" prop="">
        <el-button style="margin-bottom: 10px" @click="handleExport">
          导出
        </el-button>
        <el-button style="margin-bottom: 10px" @click="handleBack">
          返回
        </el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" stripe :data="list">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" width="90" align="center">
        <template #default="{ row }">
          <el-button type="text" @click="handleDetail(row)">明细</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="total">
      <div>销售收款合计:{{ totalAll.sale_money }}</div>
      <div>派送收款合计:{{ totalAll.driver_money }}</div>
      <div>预存款收款合计:{{ totalAll.deposit_money }}</div>
      <div>预存货收款合计:{{ totalAll.goods_money }}</div>
      <div>清欠收款合计:{{ totalAll.clear_money }}</div>
      <div>兑奖现金合计:{{ totalAll.prize_amount }}</div>
      <div>预存退款合计:{{ totalAll.back_money }}</div>
      <div>收支合计:{{ totalAll.total_amount }}</div>
    </div>
    <detail ref="detail"></detail>
  </div>
</template>
<script>
  import { downloadFile, postAction } from '@/api/Employee'
  import Detail from './components/detail.vue'
  export default {
    name: 'UserDetail',
    components: { Detail },
    beforeRouteEnter(to, from, next) {
      next((v) => {
        v.homeData = to.params
        v.fetchData()
      })
    },
    data() {
      return {
        form: {},
        loading: false,
        homeData: {},
        list: [],
        totalAll: {},
        total: 0,
        columns: [
          {
            label: '日期',
            prop: 'time_at',
            width: '',
          },
          {
            label: '员工',
            prop: 'user_name',
            width: '',
          },
          {
            label: '+销售收款',
            prop: 'sale_money',
            width: '',
          },
          {
            label: '+派送收款',
            prop: 'driver_money',
            width: '',
          },
          {
            label: '+预存款收款',
            prop: 'deposit_money',
            width: '',
          },
          {
            label: '+预存货收款',
            prop: 'goods_money',
            width: '',
          },
          {
            label: '+清欠收款',
            prop: 'clear_money',
            width: '',
          },
          {
            label: '-兑奖现金',
            prop: 'prize_amount',
            width: '',
          },
          {
            label: '-兑付现金',
            prop: 'cash_amount',
            width: '',
          },
          {
            label: '-预存退款',
            prop: 'back_money',
            width: '',
          },
          {
            label: '当日收支合计',
            prop: 'total_amount',
            width: '',
          },
        ],
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        console.log('homeData', this.homeData)
        let { data, ext, totalCount } = await postAction(
          '/reportAdmin/operate/day-form-list',
          {
            ...this.homeData,
            pageSize: -1,
          }
        )
        this.list = data
        this.totalAll = ext
        this.total = totalCount
        this.loading = false
      },
      handleDetail(row) {
        this.$refs.detail.form = { ...row, ...this.homeData }
        this.$refs.detail.showDialog = true
      },
      handleExport() {
        downloadFile(
          '/reportAdmin/operate/day-form-detail-export',
          '日销统计.xlsx',
          this.homeData
        )
      },
      handleBack() {
        this.$router.push({
          name: 'DailySale',
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .total {
    display: flex;
    padding: 15px 20px;
    justify-content: space-around;
  }
</style>
